import $ from 'jquery';

let hash = location.hash;
$(() => {
  if(hash){
    if (hash === '#news') {
      $('.c-global-header__navication__item').removeClass('is-current');
      Array.from(document.getElementsByClassName('c-global-header__navication__item')).forEach(function(list){
        if ($(list).data('id') === 'menu-03') {
          $(list).addClass('is-current');
        }
      })
    }
    $("html, body").stop().scrollTop(0);
    setTimeout(function(){
      const target2 = $(hash);
      const position2 = target2.offset().top;
      $("html, body").stop().animate({scrollTop:position2}, 500, "swing");
    }, 10);
  }

  $('a[href*="#"]').on('click', function() {
    let href = $(this).prop("href");
    let hrefPageUrl = href.split("#")[0];
    let currentUrl = location.href;
    currentUrl = currentUrl.split("#")[0];
    if(hrefPageUrl == currentUrl){
      href = "#" + href.split("#").pop()
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top;
      const speed = 500;
      $("html, body").animate({
        scrollTop: position
      }, speed, "swing");
      if(href !== "#" && href !== "") {
        location.href = href; 
        hash = href;
        if (hash === '#news') {
          $('.c-global-header__navication__item').removeClass('is-current');
          Array.from(document.getElementsByClassName('c-global-header__navication__item')).forEach(function(list){
            if ($(list).data('id') === 'menu-03') {
              $(list).addClass('is-current');
            }
          })
        }
      }
      return false;
    }
  });
});