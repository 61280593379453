import $ from 'jquery';
import 'slick-carousel';
import imagesLoaded from 'imagesloaded';

$(()=> {
  const target_top = $('.js-carousel-top');
  const $img = $('img');
  const imgLoad = imagesLoaded( target_top );
  imgLoad.on('done', () => {
    if(target_top) {
      const slick_option_top = {
        infinite: true,
        centerMode: false,
        dots: true,
        variableWidth: false,//true,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        slidesToShow: 1,
        // prevArrow: '<button class="c-button c-button--circle c-carousel__prev"><span class="c-button__body"><i class="c-icon c-icon--paging-prev"></i>前へ</span></button>',
        // nextArrow: '<button class="c-button c-button--circle c-carousel__next"><span class="c-button__body"><i class="c-icon c-icon--paging-next"></i>次へ</span></button>',
      };
      target_top.slick(slick_option_top);
    }
  });
});
